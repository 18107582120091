import { INTERSECTION_PER_ROW } from "../const/const_number";
import { ChunkDir } from "../enum/ChunkDir";
import { checkAlive } from "./checkAlive";
import { findChunk } from "./findChunk";
import { removeCapturedStones } from "./removeCapturedStones";
import { getDoubleDimensionArray, getOpponentColor, isOpponent } from "./util";

export function getCapturedChunks(updatedBoard: string[][], column: number, row: number,
    color: string, liveBoard: string[][]): number[][] {
    let left, right, top, bottom;
    if (column >= 1 && column <= INTERSECTION_PER_ROW) {
        if (column - 1 >= 1) {
            left = liveBoard[row - 1][column - 1 - 1];
        }
        if (column + 1 <= INTERSECTION_PER_ROW) {
            right = liveBoard[row - 1][column + 1 - 1];
        }
    }
    if (row >= 1 && row <= INTERSECTION_PER_ROW) {
        if (row - 1 >= 1) {
            top = liveBoard[row - 1 - 1][column - 1];
        }
        if (row + 1 <= INTERSECTION_PER_ROW) {
            bottom = liveBoard[row + 1 - 1][column - 1];
        }
    }

    // console.log("row column=", row, column);
    // console.log("left=", left);
    // console.log("right=", right);
    // console.log("top=", top);
    // console.log("bottom=", bottom);
    let capturedChunkLeft: number[][] = [];
    let capturedChunkRight: number[][] = [];
    let capturedChunkTop: number[][] = [];
    let capturedChunkBottom: number[][] = [];
    var chunkFromLeft = getDoubleDimensionArray(
        INTERSECTION_PER_ROW,
        INTERSECTION_PER_ROW,
        false
    );
    var chunkFromRight = getDoubleDimensionArray(
        INTERSECTION_PER_ROW,
        INTERSECTION_PER_ROW,
        false
    );
    var chunkFromTop = getDoubleDimensionArray(
        INTERSECTION_PER_ROW,
        INTERSECTION_PER_ROW,
        false
    );
    var chunkFromBottom = getDoubleDimensionArray(
        INTERSECTION_PER_ROW,
        INTERSECTION_PER_ROW,
        false
    );
    if (isOpponent(color, left)) {
        chunkFromLeft = findChunk(
            updatedBoard,
            INTERSECTION_PER_ROW,
            INTERSECTION_PER_ROW,
            getOpponentColor(color),
            chunkFromLeft,
            column,
            row,
            ChunkDir.CHUNK_DIR_LEFT,
            true
        );
        // console.log(
        //     "chunkFromLeft=",
        //     getOpponentColor(color),
        //     checkAlive(updatedBoard, chunkFromLeft, getOpponentColor(color)),
        //     chunkFromLeft
        // );
        if (
            !checkAlive(updatedBoard, chunkFromLeft, getOpponentColor(color))
        ) {
            capturedChunkLeft = removeCapturedStones(
                chunkFromLeft,
                updatedBoard
            );
        }
    }
    if (isOpponent(color, right)) {
        chunkFromRight = findChunk(
            updatedBoard,
            INTERSECTION_PER_ROW,
            INTERSECTION_PER_ROW,
            getOpponentColor(color),
            chunkFromRight,
            column,
            row,
            ChunkDir.CHUNK_DIR_RIGHT,
            true
        );
        // console.log(
        //     "chunkFromRight=",
        //     getOpponentColor(color),
        //     checkAlive(updatedBoard, chunkFromRight, getOpponentColor(color)),
        //     chunkFromRight
        // );
        if (
            !checkAlive(updatedBoard, chunkFromRight, getOpponentColor(color))
        ) {
            capturedChunkRight = removeCapturedStones(
                chunkFromRight,
                updatedBoard
            );
        }
    }
    if (isOpponent(color, top)) {
        chunkFromTop = findChunk(
            updatedBoard,
            INTERSECTION_PER_ROW,
            INTERSECTION_PER_ROW,
            getOpponentColor(color),
            chunkFromTop,
            column,
            row,
            ChunkDir.CHUNK_DIR_TOP,
            true
        );
        // console.log(
        //     "chunkFromTop=",
        //     getOpponentColor(color),
        //     row,
        //     column,
        //     checkAlive(updatedBoard, chunkFromTop, getOpponentColor(color)),
        //     chunkFromTop
        // );
        if (
            !checkAlive(updatedBoard, chunkFromTop, getOpponentColor(color))
        ) {
            capturedChunkTop = removeCapturedStones(
                chunkFromTop,
                updatedBoard
            );
        }
    }
    if (isOpponent(color, bottom)) {
        chunkFromBottom = findChunk(
            updatedBoard,
            INTERSECTION_PER_ROW,
            INTERSECTION_PER_ROW,
            getOpponentColor(color),
            chunkFromBottom,
            column,
            row,
            ChunkDir.CHUNK_DIR_BOTTOM,
            true
        );
        // console.log(
        //     "chunkFromBottom=",
        //     getOpponentColor(color),
        //     row,
        //     column,
        //     checkAlive(
        //         updatedBoard,
        //         chunkFromBottom,
        //         getOpponentColor(color)
        //     ),
        //     chunkFromBottom
        // );
        if (
            !checkAlive(
                updatedBoard,
                chunkFromBottom,
                getOpponentColor(color)
            )
        ) {
            capturedChunkBottom = removeCapturedStones(
                chunkFromBottom,
                updatedBoard
            );
        }
    }
    const allCapturedChunks: number[][] = [
        ...capturedChunkLeft,
        ...capturedChunkRight,
        ...capturedChunkTop,
        ...capturedChunkBottom,
    ];
    return allCapturedChunks;
}