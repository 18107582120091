// let boardMarked = {};

import { EMPTY_SPOT } from "../const/const_string";
import { ChunkDir } from "../enum/ChunkDir";
import { getDoubleDimensionArray } from "./util";


// export function doEntrance(board: string[][]) {
//     const chunks = [];
//     console.log("boxfff=", board);
//     const row_size = board.length;
//     const column_size = board[0].length;
//     //   boardMarked = createObjectFrom2DArray(row_size, column_size, 0);
//     var gathers = getDoubleDimensionArray(row_size, column_size, EMPTY_SPOT);
//     //   const info = board;
//     outerLoop: for (var i = 1; i < board.length + 1; i++) {
//         const aRow = board[i - 1];
//         for (var j = 1; j < aRow.length + 1; j++) {
//             const digit = aRow[j - 1];
//             if (digit != EMPTY_SPOT) {
//                 gathers = findChunk(
//                     board,
//                     row_size,
//                     column_size,
//                     digit,
//                     gathers,
//                     i,
//                     j
//                 );
//                 console.log("gathers=", gathers);
//                 break outerLoop;
//             }
//         }
//     }
//     //   console.log("boardMarked", boardMarked);
// }
export function findChunk(
    board: string[][],
    row_size: number,
    column_size: number,
    color: string,
    gathers: boolean[][],
    curCol: number,
    curRow: number,
    chunkDir: ChunkDir,
    checkChunkDirOnly: boolean,
) {
    const leftPos = curRow + "_" + (curCol - 1);
    const rightPos = curRow + "_" + (curCol + 1);
    const upPos = curRow - 1 + "_" + curCol;
    const downPos = curRow + 1 + "_" + curCol;

    //left
    if ((!checkChunkDirOnly || chunkDir === ChunkDir.CHUNK_DIR_LEFT) &&
        curCol != 1 &&
        board[curRow - 1][curCol - 1 - 1] == color &&
        !gathers[curRow - 1][curCol - 1 - 1]
    ) {
        gathers[curRow - 1][curCol - 1 - 1] = true;
        gathers = findChunk(
            board,
            row_size,
            column_size,
            color,
            gathers,
            curCol - 1,
            curRow,
            chunkDir,
            false
        );
    }
    //left up
    // if (
    //     curCol != 1 &&
    //     curRow != row_size &&
    //     board[curRow - 1 - 1][curCol - 1 - 1] == color &&
    //     !gathers[curRow - 1 - 1][curCol - 1 - 1]
    // ) {
    //     gathers[curRow - 1 - 1][curCol - 1 - 1] = true;
    //     gathers = findChunk(
    //         board,
    //         row_size,
    //         column_size,
    //         color,
    //         gathers,
    //         curCol - 1,
    //         curRow - 1
    //     );
    // }
    //right
    if ((!checkChunkDirOnly || chunkDir === ChunkDir.CHUNK_DIR_RIGHT) &&
        curCol != column_size &&
        board[curRow - 1][curCol - 1 + 1] == color &&
        !gathers[curRow - 1][curCol - 1 + 1]
    ) {
        gathers[curRow - 1][curCol - 1 + 1] = true;
        gathers = findChunk(
            board,
            row_size,
            column_size,
            color,
            gathers,
            curCol + 1,
            curRow,
            chunkDir,
            false
        );
    }
    //right down
    // if (
    //     curCol != column_size &&
    //     curRow != row_size &&
    //     board[curRow - 1 + 1][curCol - 1 + 1] == color &&
    //     !gathers[curRow - 1 + 1][curCol - 1 + 1]
    // ) {
    //     gathers[curRow - 1 + 1][curCol - 1 + 1] = true;
    //     gathers = findChunk(
    //         board,
    //         row_size,
    //         column_size,
    //         color,
    //         gathers,
    //         curCol + 1,
    //         curRow + 1
    //     );
    // }

    //up
    if ((!checkChunkDirOnly || chunkDir === ChunkDir.CHUNK_DIR_TOP) &&
        curRow != 1 &&
        board[curRow - 1 - 1][curCol - 1] == color &&
        !gathers[curRow - 1 - 1][curCol - 1]
    ) {
        gathers[curRow - 1 - 1][curCol - 1] = true;
        gathers = findChunk(
            board,
            row_size,
            column_size,
            color,
            gathers,
            curCol,
            curRow - 1,
            chunkDir,
            false
        );
    }
    //up right
    // if (
    //     curRow != 1 &&
    //     curCol != column_size &&
    //     board[curRow - 1 - 1][curCol - 1 + 1] == color &&
    //     !gathers[curRow - 1 - 1][curCol - 1 + 1]
    // ) {
    //     gathers[curRow - 1 - 1][curCol - 1 + 1] = true;
    //     gathers = findChunk(
    //         board,
    //         row_size,
    //         column_size,
    //         color,
    //         gathers,
    //         curCol + 1,
    //         curRow - 1
    //     );
    // }
    //down
    if ((!checkChunkDirOnly || chunkDir === ChunkDir.CHUNK_DIR_BOTTOM) &&
        curRow != row_size &&
        board[curRow - 1 + 1][curCol - 1] == color &&
        !gathers[curRow - 1 + 1][curCol - 1]
    ) {
        gathers[curRow - 1 + 1][curCol - 1] = true;
        gathers = findChunk(
            board,
            row_size,
            column_size,
            color,
            gathers,
            curCol,
            curRow + 1,
            chunkDir,
            false
        );
    }
    //down left
    // if (
    //     curRow != row_size &&
    //     curCol != 1 &&
    //     board[curRow - 1 + 1][curCol - 1 - 1] == color &&
    //     !gathers[curRow - 1 + 1][curCol - 1 - 1]
    // ) {
    //     gathers[curRow - 1 + 1][curCol - 1 - 1] = true;
    //     gathers = findChunk(
    //         board,
    //         row_size,
    //         column_size,
    //         color,
    //         gathers,
    //         curCol - 1,
    //         curRow + 1
    //     );
    // }

    //   if (curRow != 1 && board[upPos] == color && !boardMarked[upPos]) {
    //     boardMarked[upPos] = true;
    //     gathers.push(upPos);
    //     // array_push(gathers, upPos);
    //     gathers = newFindChunk(
    //       board,
    //       row_size,
    //       column_size,
    //       color,
    //       gathers,
    //       curCol,
    //       curRow - 1
    //     );
    //     // echo "found top<br>";
    //   }
    //   if (
    //     curRow != column_size &&
    //     board[downPos] == color &&
    //     !boardMarked[downPos]
    //   ) {
    //     boardMarked[downPos] = true;
    //     gathers.push(downPos);
    //     // array_push(gathers, downPos);
    //     gathers = newFindChunk(
    //       board,
    //       row_size,
    //       column_size,
    //       color,
    //       gathers,
    //       curCol,
    //       curRow + 1
    //     );
    //   }
    return gathers;
}
