import { BOARD_POSITION_LABEL_WIDTH, CELL_PER_ROW, INTERSECTION_PER_ROW, NECESSARY_COMPLETE_BORDER, OFFSET_EMPTY_AREA } from "../const/const_number";
import { EMPTY_SPOT } from "../const/const_string";
import { getDoubleDimensionArray } from "./util";

export function getWholeBoardWidth(isMobile: boolean, screenWidth: number, cellSizeWithOneSideBorder: number) {
    // console.log("getWholeBoardWidth 1=", cellSizeWithOneSideBorder * (CELL_PER_ROW + 2 + OFFSET_EMPTY_AREA * 2));
    // console.log("getWholeBoardWidth 2=", 34 * (CELL_PER_ROW + 2 + OFFSET_EMPTY_AREA * 2));
    // return cellSizeWithOneSideBorder * (CELL_PER_ROW + 2 + OFFSET_EMPTY_AREA * 2);
    if (isMobile) {
        return screenWidth;
    } else {
        return cellSizeWithOneSideBorder * (CELL_PER_ROW + (BOARD_POSITION_LABEL_WIDTH * 2) + OFFSET_EMPTY_AREA * 2) + NECESSARY_COMPLETE_BORDER;

    }
}

export function getEmptyBoard() {
    return getDoubleDimensionArray(
        INTERSECTION_PER_ROW,
        INTERSECTION_PER_ROW,
        EMPTY_SPOT
    )
}