import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Homepage from "./route/Homepage";
import GenBoard from "./route/GenBoard";
import Contact from "./route/Contact";

function App() {
  return (
    <div
      className="dfr dfjc"
      style={{ minHeight: "100vh", background: "", color: "black" }}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Homepage />} />
          {/* <Route path="/gen" element={<GenBoard />} /> */}
          <Route path="/contact" element={<Contact />} />
          {/* <Route path="/filer" element={<FileReader />} />
          <Route path="/a" element={<SolverA />} /> */}
          {/* <Route path="/dial" element={<Dialoguer />} />
          <Route key={"all"} path="/gad" element={<Gadder />} />
          <Route key={"/speech"} path="/speech" element={<HookSpeech />} />
          <Route key={"single"} path="/gad/:quiz:turn" element={<Gadder />} />
          <Route path="/elem" element={<Element />} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
