import { EMPTY_SPOT } from "../const/const_string";

export function removeCapturedStones(chunkFromADirection: string[][], updatedBoard: string[][]): number[][] {
    const capturedRecord = [];
    for (var i = 1; i <= chunkFromADirection.length; i++) {
        for (var j = 1; j <= chunkFromADirection[i - 1].length; j++) {
            if (chunkFromADirection[i - 1][j - 1]) {
                updatedBoard[i - 1][j - 1] = EMPTY_SPOT;
                capturedRecord.push([i, j]);
            }
        }
    }
    // return [updatedBoard, capturedRecord];
    return capturedRecord;
}