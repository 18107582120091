export const mainBg = "#2c3c55";
// export const whyRequireHere = mainBg;//for mobile, else, it will be white (don't know why...)
export const whyRequireHere = "red";//for mobile, else, it will be white (don't know why...)
export const palette1 = {
    // boardColor: "#ddbc6b", labelColor: "#665544",
    // labelFontColor: "#b6a887", lineColor: "#ae9454"
    // boardColor: "#FED8B1", labelColor: "#6F4E37",
    // labelFontColor: "#b6a887", lineColor: "#ae9454"
    // boardColor: "#ECB176", labelColor: "#6F4E37",
    // labelFontColor: "#b6a887", lineColor: "#ae9454"
    // boardColor: "#E9C874", labelColor: "#A34343",
    // labelFontColor: "#b6a887", lineColor: "#ae9454"
    // boardColor: "#FFC436", labelColor: "#0C356A",
    // labelFontColor: "#b6a887", lineColor: "#ae9454"
    // boardColor: "#F4CE14", labelColor: "#495E57",
    // labelFontColor: "#b6a887", lineColor: "#ae9454"
    boardColor: "#debe64", labelColor: "#645a41",
    labelFontColor: "#b6a887", lineColor: "#ae9454"
};

export const tourneyButton = {
    normal: "#debe64", active: "#56420c",
};
export const ICON_BTN_COLOR = "#1f447d";
export const ICON_BTN_EDIT_MODE_COLOR = "#fff";
export const DEBUG_CELL_COLOR = "";
export const HILIGHT_TOURNAMENT = "blue";
// export const DEBUG_CELL_COLOR = "pink";
