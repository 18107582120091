// export const HOSTING_WEIQI = "http://localhost:5555";
// export const HOSTING_WEIQI = "http://localhost:8888";
let HOSTING_WEIQI = "https://programmon.com/backend";
const PATH_PREFIX = "/board";

if (window.location.hostname.indexOf("localhost") >= 0) {
    HOSTING_WEIQI = "http://localhost:8888";
} else {
}
export { HOSTING_WEIQI, PATH_PREFIX }

