import React, { useState, useEffect } from "react";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { HOSTING_WEIQI, PATH_PREFIX } from "../const/const";
import { GameData } from "../type/GameData";
import {
  getNumberFromChar,
  getDoubleDimensionArray,
  getOpponentColor,
  getStoneColorFromMove,
  isOpponent,
  getCharFromNumber,
  isDataFromLeela,
} from "../util/util";

import {
  DEFAULT_CELL_SIZE,
  DIRECTION_NUMBER,
  JUMP_MOVE_AMOUNT,
  STONE_SCALE,
} from "../const/const_number";
import { BLACK_SPOT, EMPTY_SPOT, WHITE_SPOT } from "../const/const_string";
import { findChunk } from "../util/findChunk";
import { ChunkDir } from "../enum/ChunkDir";
import { checkAlive } from "../util/checkAlive";
import { removeCapturedStones } from "../util/removeCapturedStones";
import { StashData } from "../type/StashData";
import { getCapturedChunks } from "../util/getCapturedChunks";
import { TourneyData } from "../type/TourneyData";
import {
  DEBUG_CELL_COLOR,
  ICON_BTN_COLOR,
  ICON_BTN_EDIT_MODE_COLOR,
  mainBg,
} from "../const/const_color";
import { getEmptyBoard, getWholeBoardWidth } from "../util/util_board";
import "../style/style.css";
import { MyIconButton } from "../style/MyIconButton";

const READ_AMOUNT = 50;

interface simpleObj {
  name?: any;
  value?: any;
}
const Contact = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [selectedTournament, setSelectedTournament] = useState(1);
  // const [oneGame, setOneGame] = useState<string[]>([]);
  // const [boardTable, setBoardTable] = useState<string[][]>(
  //   getDoubleDimensionArray(
  //     INTERSECTION_PER_ROW,
  //     INTERSECTION_PER_ROW,
  //     EMPTY_SPOT
  //   )
  // );
  // const [currentMove, setCurrentMove] = useState(0);

  const [gameDataList, setGameDataList] = useState<GameData[]>([]);
  const [tourneyList, setTourneyList] = useState<TourneyData[]>([]);

  const clickPrevPage = () => {
    if (currentPage > 1) {
      const prevPage = currentPage - 1;
      setCurrentPage(prevPage);
      fetchGameList(selectedTournament, currentPage);
    }
  };

  const clickNextPage = () => {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    fetchGameList(selectedTournament, nextPage);
  };
  const clickTargetPage = (page: number) => {
    setCurrentPage(page);
    fetchGameList(selectedTournament, page);
  };
  const clickJumpNext = (
    gameData: GameData,
    direction: DIRECTION_NUMBER,
    jumpMoveAmount: number = JUMP_MOVE_AMOUNT
  ) => {
    let totalUpdatedGameData: GameData | undefined = { ...gameData };
    for (var i = 0; i < jumpMoveAmount; i++) {
      totalUpdatedGameData = wrapClickNextMove(
        totalUpdatedGameData!,
        direction
      );
      if (!totalUpdatedGameData) {
        break;
      }
      // setTimeout(() => {
      //   clickNextMove(gameData, direction);
      // }, 100);
    }
  };
  // const clickJumpNext = (gameData: GameData, direction: DIRECTION_NUMBER) => {
  //   for (var i = 0; i < JUMP_MOVE_AMOUNT; i++) {
  //     setTimeout(() => {
  //       clickNextMove(gameData, direction);
  //     }, 100);
  //   }
  // };

  const wrapClickNextMove = (
    gameData: GameData,
    direction: DIRECTION_NUMBER
  ): GameData | undefined => {
    const updatedGameData = clickNextMove(gameData, direction);
    console.log("updatedGameData", updatedGameData);
    if (!updatedGameData) {
      return;
    }
    setGameDataList(
      gameDataList.map((item) => {
        if (updatedGameData && updatedGameData.gid === item.gid) {
          return updatedGameData;
        }
        return item;
      })
    );
    return updatedGameData;
  };

  const clickNextMove = (gd: GameData, direction: DIRECTION_NUMBER) => {
    // console.log("clickNextMove", gd);
    if (!gd) {
      return;
    }
    let { gid, gameplayOnly, liveBoard, move } = gd;
    const targetPos = move + direction;
    // console.log("targetPos=", targetPos, move, direction);
    if (targetPos < 0) {
      return;
    }
    if (targetPos == 0) {
      gd.liveBoard = getEmptyBoard();
      gd.move = 0;
      gd.capturedMap = {};
      gd.inEditMode = false;
      gd.latestMoveRowColPos = [];
      return gd;
    }
    console.log("targetPos", targetPos);
    const played = gameplayOnly[targetPos - 1];
    if (!played) {
      return;
    }
    let column = 0;
    let row = 0;
    let updatedBoard: string[][];
    if (direction == -1) {
      const currentPlayed = gameplayOnly[move - 1];
      const currentColumn = getNumberFromChar(currentPlayed[2]);
      const currentRow = getNumberFromChar(currentPlayed[3]);
      if (played) {
        gd.lastStoneColor = played[0];
        column = getNumberFromChar(played[2]);
        row = getNumberFromChar(played[3]);
      }
      updatedBoard = [...liveBoard];
      updatedBoard[currentRow - 1][currentColumn - 1] = EMPTY_SPOT;

      if (move in gd.capturedMap) {
        for (var i = 0; i < gd.capturedMap[move].length; i++) {
          const capturedStone = gd.capturedMap[move][i];
          // console.log("capturedStone=", capturedStone);
          updatedBoard[capturedStone[0] - 1][capturedStone[1] - 1] =
            getOpponentColor(getStoneColorFromMove(gameplayOnly, move));
        }
      }
    } else if (direction == 1) {
      const color = played[0];
      gd.lastStoneColor = color;
      column = getNumberFromChar(played[2]);
      row = getNumberFromChar(played[3]);
      // console.log("row column=", row, column);
      updatedBoard = [...liveBoard];
      updatedBoard[row - 1][column - 1] = color;

      const allCapturedChunks = getCapturedChunks(
        updatedBoard,
        column,
        row,
        color,
        liveBoard
      );

      if (allCapturedChunks.length > 0) {
        gd.capturedMap[gd.move + direction] = allCapturedChunks;
      }
    }
    // console.log("capturedMap=", gd.capturedMap);
    gd.latestMoveRowColPos = [row, column];
    gd.liveBoard = updatedBoard!;
    gd.move = gd.move + direction;
    return gd;
  };

  async function fetchTournamentList() {
    const urlencoded = new URLSearchParams();
    urlencoded.append("page_no", currentPage.toString());
    urlencoded.append("per_page", "6");

    let result = await fetch(HOSTING_WEIQI + PATH_PREFIX + "/tourneys", {
      // method: "GET",
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: urlencoded,
    });
    const data = await result.json();
    console.log("result tourneys=", data);
    const tourneyList = data.data;
    setTourneyList(tourneyList);
  }
  async function fetchGameList(tournamentId: number, targetPage: number) {
    const urlencoded = new URLSearchParams();
    urlencoded.append("page_no", targetPage.toString());
    urlencoded.append("per_page", "6");
    urlencoded.append("tournament_id", tournamentId.toString());
    console.log("fetch urlencoded=", urlencoded);

    let result = await fetch(HOSTING_WEIQI + PATH_PREFIX + "/tourney_games", {
      // method: "GET",
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: urlencoded,
    });
    const data = await result.json();
    console.log("result exchange=", data);
    const gameList = data.data;
    setTotalPage(data.total_pages);
    gameList &&
      gameList.forEach((element: GameData) => {
        const pickOne = element;
        const raw = pickOne.data;
        let pieces = raw.split(";");
        if (isDataFromLeela(raw)) {
          element.gameplayOnly = pieces.slice(2);
        } else {
          element.gameplayOnly = pieces.slice(1);
        }
        const emptyBoard = getEmptyBoard();
        element.liveBoard = emptyBoard;
        element.move = 0;
        element.capturedMap = {};
        element.inEditMode = false;
        // setOneGame(pieces.slice(2));
      });
    // console.log("gameplayOnly", gameList);
    setGameDataList(gameList);
    // const pickOne = gameList[0];
    // const raw = pickOne.data;
    // let pieces = raw.split(";");
    // setOneGame(pieces.slice(2));

    // ;GM[1]FF[4]RU[Chinese]DT[2021-07-24]SZ[19]KM[7.5]PB[Leela 0.11.0]PW[Human]RE[W+Resign];B[pp];
  }

  const clickTourney = (tourney: TourneyData) => {
    setCurrentPage(1);
    setSelectedTournament(tourney.eid);
    fetchGameList(tourney.eid, 1);
  };

  const exitEditMode = (gameData: GameData) => {
    console.log("exitEditMode", gameData);
    setGameDataList(
      gameDataList.map((item2) => {
        if (item2.gid === gameData.gid) {
          const stashState: StashData = item2.stashData;
          console.log("stashState", stashState);
          console.log("stashcheck 2=" + stashState.move);
          console.log("stashcheck 2.1=", stashState.liveBoard);

          return {
            ...item2,
            gameplayOnly: [...stashState.gameplayOnly],
            inEditMode: false,
            liveBoard: [...stashState.liveBoard],
            latestMoveRowColPos: stashState.latestMoveRowColPos,
            capturedMap: stashState.capturedMap,
            move: stashState.move,
          };
          // return { ...item2, inEditMode: false };
        }
        return item2;
      })
    );
  };
  const clickCell = (
    key: string,
    row: number,
    column: number,
    item: string,
    gd: GameData
  ) => {
    console.log("clickCell", gd);
    if (gd.liveBoard[row - 1][column - 1] != EMPTY_SPOT) {
      console.log("clickCell return");

      return;
    }
    setGameDataList(
      gameDataList.map((item) => {
        if (gd.gid === item.gid) {
          let stashState: StashData;
          if (!item.inEditMode) {
            stashState = {
              gameplayOnly: JSON.parse(JSON.stringify(item.gameplayOnly)),
              // liveBoard: [...item.liveBoard],
              liveBoard: JSON.parse(JSON.stringify(item.liveBoard)),
              lastStoneColor: gd.lastStoneColor,
              latestMoveRowColPos: item.latestMoveRowColPos,
              capturedMap: item.capturedMap,
              move: item.move,
              entryEditMove: item.move + 1,
            };
            console.log("slicer=", stashState.gameplayOnly);
            item.gameplayOnly = JSON.parse(
              JSON.stringify(item.gameplayOnly)
            ).slice(0, item.move);
          } else {
            stashState = item.stashData;
          }

          console.log("stashcheck 1=" + item.move);
          console.log("stashcheck 1.1=" + stashState.liveBoard);

          // const color = played[0];
          // const color = getOpponentColor(stashState.lastStoneColor);
          const color = getOpponentColor(
            stashState.lastStoneColor ? stashState.lastStoneColor : WHITE_SPOT //if first move, do this so we can put black stone
          );
          // column = getCharPosition(played[2]);
          // row = getCharPosition(played[3]);
          console.log("row column=", row, column);
          const updatedBoard = [...gd.liveBoard];
          updatedBoard[row - 1][column - 1] = color;
          stashState.lastStoneColor = color;
          item.move++;
          item.gameplayOnly.push(
            color +
              "[" +
              getCharFromNumber(column) +
              getCharFromNumber(row) +
              "]"
          );
          const allCapturedChunks = getCapturedChunks(
            updatedBoard,
            column,
            row,
            color,
            gd.liveBoard
          );

          if (allCapturedChunks.length > 0) {
            // gd.capturedMap[gd.move + 1] = allCapturedChunks;
            item.capturedMap[gd.move + 1] = allCapturedChunks;
          }

          // const updatedGameData = clickNextMove(gameData, 1);
          return { ...item, inEditMode: true, stashData: stashState };
        }
        return item;
      })
    );
  };

  const getStone = (
    key: string,
    rowPos: number,
    columnPos: number,
    item: string,
    gameData: GameData
  ) => {
    return (
      <div
        key={key}
        style={{ width: DEFAULT_CELL_SIZE, height: DEFAULT_CELL_SIZE }}
      >
        <div
          className={
            "dfr dfjc dfac " +
            (item === BLACK_SPOT
              ? "myStone black-stone"
              : item === WHITE_SPOT
              ? "myStone white-stone"
              : "")
          }
          onClick={() => {
            clickCell(key, rowPos, columnPos, item, gameData);
          }}
          style={{
            // margin: item === EMPTY_SPOT ? "0px" : "2px",
            width: STONE_SCALE + "%",
            height: STONE_SCALE + "%",
            boxSizing: "border-box",
            // border: "1px solid pink",
            background:
              item === BLACK_SPOT
                ? "black"
                : item === WHITE_SPOT
                ? "white"
                : DEBUG_CELL_COLOR,
          }}
        >
          {gameData.latestMoveRowColPos &&
          gameData.latestMoveRowColPos[0] === rowPos &&
          gameData.latestMoveRowColPos[1] === columnPos ? (
            <div
              style={{
                width: 8,
                height: 8,
                background: "red",
                borderRadius: "50%",
              }}
            ></div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };
  useEffect(() => {
    fetchGameList(selectedTournament, currentPage);
  }, []);
  useEffect(() => {
    fetchTournamentList();
  }, [currentPage]);

  return (
    <div className="dfr" style={{ width: "100%", background: mainBg }}></div>
  );
};

export default Contact;
