import React, { useState, useEffect } from "react";
import { FormControl, IconButton } from "@mui/material";
import { HOSTING_WEIQI, PATH_PREFIX } from "../const/const";
import { GameData } from "../type/GameData";
import {
  getNumberFromChar,
  getDoubleDimensionArray,
  getOpponentColor,
  getStoneColorFromMove,
  isOpponent,
  getCharFromNumber,
  isDataFromLeela,
} from "../util/util";
import {
  FastForward,
  FastRewind,
  FirstPage,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
  LastPage,
  PlayArrow,
  SkipNext,
  SkipPrevious,
} from "@mui/icons-material";
import {
  BOARD_POSITION_LABEL_WIDTH,
  BOARDS_PER_PAGE,
  BORDER_RADIUS_BOARD,
  CELL_PER_ROW,
  DEFAULT_CELL_SIZE,
  DIRECTION_NUMBER,
  ESTIMATE_END_MOVE,
  GAME_CONTROL_BAR_HEIGHT,
  INTERSECTION_PER_ROW,
  JUMP_MOVE_AMOUNT,
  NECESSARY_COMPLETE_BORDER,
  OFFSET_EMPTY_AREA,
  PAGE_CONTROL_BAR_HEIGHT,
  PLAY_BUTTON_SIZE,
  STONE_SCALE,
} from "../const/const_number";
import { BLACK_SPOT, EMPTY_SPOT, WHITE_SPOT } from "../const/const_string";
import { findChunk } from "../util/findChunk";
import { ChunkDir } from "../enum/ChunkDir";
import { checkAlive } from "../util/checkAlive";
import { removeCapturedStones } from "../util/removeCapturedStones";
import { StashData } from "../type/StashData";
import { getCapturedChunks } from "../util/getCapturedChunks";
import { TourneyData } from "../type/TourneyData";
import {
  DEBUG_CELL_COLOR,
  ICON_BTN_COLOR,
  ICON_BTN_EDIT_MODE_COLOR,
  mainBg,
  palette1,
  tourneyButton,
  whyRequireHere,
} from "../const/const_color";
import { getEmptyBoard, getWholeBoardWidth } from "../util/util_board";
import "../style/style.css";
import { MyIconButton } from "../style/MyIconButton";
import "../style/mySidebar.css";
import "../style/myButton.css";
const READ_AMOUNT = 50;

interface simpleObj {
  name?: any;
  value?: any;
}
const Homepage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [selectedTournament, setSelectedTournament] = useState(1);
  // const [oneGame, setOneGame] = useState<string[]>([]);
  // const [boardTable, setBoardTable] = useState<string[][]>(
  //   getDoubleDimensionArray(
  //     INTERSECTION_PER_ROW,
  //     INTERSECTION_PER_ROW,
  //     EMPTY_SPOT
  //   )
  // );
  // const [currentMove, setCurrentMove] = useState(0);

  const [gameDataList, setGameDataList] = useState<GameData[]>([]);
  const [tourneyList, setTourneyList] = useState<TourneyData[]>([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const screenWidth = window.innerWidth;
  const isMobile = screenWidth <= 768;
  // const screenWidth = 1000;
  // const isMobile = false;
  // const isMobile = true;
  const [dynamicCellSize, setDynamicCellSize] =
    useState<number>(DEFAULT_CELL_SIZE);

  // console.log("screenw=", screenWidth);

  const clickTargetPage = (page: number) => {
    setCurrentPage(page);
    fetchGameList(selectedTournament, page);
  };
  const clickJumpNext = (
    gameData: GameData,
    direction: DIRECTION_NUMBER,
    jumpMoveAmount: number = JUMP_MOVE_AMOUNT
  ) => {
    let totalUpdatedGameData: GameData | undefined = { ...gameData };
    for (var i = 0; i < jumpMoveAmount; i++) {
      totalUpdatedGameData = wrapClickNextMove(
        totalUpdatedGameData!,
        direction
      );
      if (!totalUpdatedGameData) {
        break;
      }
      // setTimeout(() => {
      //   clickNextMove(gameData, direction);
      // }, 100);
    }
  };
  // const clickJumpNext = (gameData: GameData, direction: DIRECTION_NUMBER) => {
  //   for (var i = 0; i < JUMP_MOVE_AMOUNT; i++) {
  //     setTimeout(() => {
  //       clickNextMove(gameData, direction);
  //     }, 100);
  //   }
  // };

  const wrapClickNextMove = (
    gameData: GameData,
    direction: DIRECTION_NUMBER
  ): GameData | undefined => {
    const updatedGameData = clickNextMove(gameData, direction);
    // console.log("updatedGameData", updatedGameData);
    if (!updatedGameData) {
      return;
    }
    setGameDataList(
      gameDataList.map((item) => {
        if (updatedGameData && updatedGameData.gid === item.gid) {
          return updatedGameData;
        }
        return item;
      })
    );
    return updatedGameData;
  };

  const retrieveDataFromStash = (item2: GameData) => {
    const stashState: StashData = item2.stashData;
    return {
      ...item2,
      gameplayOnly: [...stashState.gameplayOnly],
      inEditMode: false,
      liveBoard: [...stashState.liveBoard],
      latestMoveRowColPos: stashState.latestMoveRowColPos,
      capturedMap: stashState.capturedMap,
      move: stashState.move,
    };
  };

  const clickNextMove = (gd: GameData, direction: DIRECTION_NUMBER) => {
    // console.log("clickNextMove", gd);
    if (!gd) {
      return;
    }
    let { gid, gameplayOnly, liveBoard, move } = gd;
    const targetPos = move + direction;
    // console.log("targetPos=", targetPos, move, direction);
    if (targetPos < 0) {
      return;
    }
    if (targetPos == 0) {
      gd.liveBoard = getEmptyBoard();
      gd.move = 0;
      gd.capturedMap = {};
      if (gd.inEditMode) {
        gd = retrieveDataFromStash(gd);
        gd.inEditMode = false;
      }
      gd.latestMoveRowColPos = [];
      return gd;
    }
    // console.log("targetPos", targetPos);
    const played = gameplayOnly[targetPos - 1];
    if (!played) {
      return;
    }
    let column = 0;
    let row = 0;
    let updatedBoard: string[][];
    if (direction == -1) {
      const currentPlayed = gameplayOnly[move - 1];
      const currentColumn = getNumberFromChar(currentPlayed[2]);
      const currentRow = getNumberFromChar(currentPlayed[3]);
      if (played) {
        gd.lastStoneColor = played[0];
        column = getNumberFromChar(played[2]);
        row = getNumberFromChar(played[3]);
      }
      updatedBoard = [...liveBoard];
      updatedBoard[currentRow - 1][currentColumn - 1] = EMPTY_SPOT;

      if (move in gd.capturedMap) {
        for (var i = 0; i < gd.capturedMap[move].length; i++) {
          const capturedStone = gd.capturedMap[move][i];
          // console.log("capturedStone=", capturedStone);
          updatedBoard[capturedStone[0] - 1][capturedStone[1] - 1] =
            getOpponentColor(getStoneColorFromMove(gameplayOnly, move));
        }
      }
    } else if (direction == 1) {
      const color = played[0];
      gd.lastStoneColor = color;
      column = getNumberFromChar(played[2]);
      row = getNumberFromChar(played[3]);
      // console.log("row column=", row, column);
      updatedBoard = [...liveBoard];
      updatedBoard[row - 1][column - 1] = color;

      const allCapturedChunks = getCapturedChunks(
        updatedBoard,
        column,
        row,
        color,
        liveBoard
      );

      if (allCapturedChunks.length > 0) {
        gd.capturedMap[gd.move + direction] = allCapturedChunks;
      }
    }
    // console.log("capturedMap=", gd.capturedMap);
    gd.latestMoveRowColPos = [row, column];
    gd.liveBoard = updatedBoard!;
    gd.move = gd.move + direction;
    return gd;
  };

  async function fetchTournamentList() {
    const urlencoded = new URLSearchParams();
    urlencoded.append("page_no", currentPage.toString());
    urlencoded.append("per_page", BOARDS_PER_PAGE.toString());

    let result = await fetch(HOSTING_WEIQI + PATH_PREFIX + "/tourneys", {
      // method: "GET",
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: urlencoded,
    });
    const data = await result.json();
    // console.log("result tourneys=", data);
    const tourneyList = data.data;
    setTourneyList(tourneyList);
  }
  async function fetchGameList(tournamentId: number, targetPage: number) {
    const urlencoded = new URLSearchParams();
    urlencoded.append("page_no", targetPage.toString());
    urlencoded.append("per_page", "6");
    urlencoded.append("tournament_id", tournamentId.toString());
    // console.log("fetch urlencoded=", urlencoded);

    let result = await fetch(HOSTING_WEIQI + PATH_PREFIX + "/tourney_games", {
      // method: "GET",
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: urlencoded,
    });
    const data = await result.json();
    // console.log("result fetchGameList=", data);
    const gameList = data.data;
    setTotalPage(data.total_pages);
    gameList &&
      gameList.forEach((element: GameData) => {
        const pickOne = element;
        const raw = pickOne.data;
        let pieces = raw.split(";");
        if (isDataFromLeela(raw)) {
          element.gameplayOnly = pieces.slice(2);
        } else {
          element.gameplayOnly = pieces.slice(1);
        }
        const emptyBoard = getEmptyBoard();
        element.liveBoard = emptyBoard;
        element.move = 0;
        element.capturedMap = {};
        element.inEditMode = false;
        // setOneGame(pieces.slice(2));
      });
    // console.log("gameplayOnly", gameList);
    setGameDataList(gameList);
    // const pickOne = gameList[0];
    // const raw = pickOne.data;
    // let pieces = raw.split(";");
    // setOneGame(pieces.slice(2));

    // ;GM[1]FF[4]RU[Chinese]DT[2021-07-24]SZ[19]KM[7.5]PB[Leela 0.11.0]PW[Human]RE[W+Resign];B[pp];
  }

  const clickTourney = (tourney: TourneyData) => {
    setCurrentPage(1);
    setSelectedTournament(tourney.eid);
    fetchGameList(tourney.eid, 1);
    if (isMobile) {
      toggleSidebar();
    }
  };

  const exitEditMode = (gameData: GameData) => {
    // console.log("exitEditMode", gameData);
    setGameDataList(
      gameDataList.map((item2) => {
        if (item2.gid === gameData.gid) {
          // const stashState: StashData = item2.stashData;
          // console.log("stashState", stashState);
          // console.log("stashcheck 2=" + stashState.move);
          // console.log("stashcheck 2.1=", stashState.liveBoard);

          // return {
          //   ...item2,
          //   gameplayOnly: [...stashState.gameplayOnly],
          //   inEditMode: false,
          //   liveBoard: [...stashState.liveBoard],
          //   latestMoveRowColPos: stashState.latestMoveRowColPos,
          //   capturedMap: stashState.capturedMap,
          //   move: stashState.move,
          // };
          return retrieveDataFromStash(item2);
        }
        return item2;
      })
    );
  };
  const clickCell = (
    key: string,
    row: number,
    column: number,
    item: string,
    gd: GameData
  ) => {
    // console.log("clickCell", gd);
    if (gd.liveBoard[row - 1][column - 1] != EMPTY_SPOT) {
      // console.log("clickCell return");
      return;
    }
    setGameDataList(
      gameDataList.map((item) => {
        if (gd.gid === item.gid) {
          let stashState: StashData;
          if (!item.inEditMode) {
            stashState = {
              gameplayOnly: JSON.parse(JSON.stringify(item.gameplayOnly)),
              // liveBoard: [...item.liveBoard],
              liveBoard: JSON.parse(JSON.stringify(item.liveBoard)),
              lastStoneColor: gd.lastStoneColor,
              latestMoveRowColPos: item.latestMoveRowColPos,
              capturedMap: item.capturedMap,
              move: item.move,
              entryEditMove: item.move + 1,
            };
            // console.log("slicer=", stashState.gameplayOnly);
            item.gameplayOnly = JSON.parse(
              JSON.stringify(item.gameplayOnly)
            ).slice(0, item.move);
          } else {
            stashState = item.stashData;
          }

          // console.log("stashcheck 1=" + item.move);
          // console.log("stashcheck 1.1=" + stashState.liveBoard);

          // const color = played[0];
          // const color = getOpponentColor(stashState.lastStoneColor);
          const color = getOpponentColor(
            stashState.lastStoneColor ? stashState.lastStoneColor : WHITE_SPOT //if first move, do this so we can put black stone
          );
          // column = getCharPosition(played[2]);
          // row = getCharPosition(played[3]);
          // console.log("row column=", row, column);
          const updatedBoard = [...gd.liveBoard];
          updatedBoard[row - 1][column - 1] = color;
          stashState.lastStoneColor = color;
          item.move++;
          item.gameplayOnly.push(
            color +
              "[" +
              getCharFromNumber(column) +
              getCharFromNumber(row) +
              "]"
          );
          const allCapturedChunks = getCapturedChunks(
            updatedBoard,
            column,
            row,
            color,
            gd.liveBoard
          );

          if (allCapturedChunks.length > 0) {
            // gd.capturedMap[gd.move + 1] = allCapturedChunks;
            item.capturedMap[gd.move + 1] = allCapturedChunks;
          }

          // const updatedGameData = clickNextMove(gameData, 1);
          return { ...item, inEditMode: true, stashData: stashState };
        }
        return item;
      })
    );
  };

  const getStone = (
    key: string,
    rowPos: number,
    columnPos: number,
    item: string,
    gameData: GameData
  ) => {
    return (
      <div
        key={key}
        style={{ width: dynamicCellSize, height: dynamicCellSize }}
      >
        <div
          className={
            "dfr dfjc dfac " +
            (item === BLACK_SPOT
              ? "myStone black-stone"
              : item === WHITE_SPOT
              ? "myStone white-stone"
              : "")
          }
          onClick={() => {
            clickCell(key, rowPos, columnPos, item, gameData);
          }}
          style={{
            // margin: item === EMPTY_SPOT ? "0px" : "2px",
            width: STONE_SCALE + "%",
            height: STONE_SCALE + "%",
            boxSizing: "border-box",
            // border: "1px solid pink",
            background:
              item === BLACK_SPOT
                ? "black"
                : item === WHITE_SPOT
                ? "white"
                : DEBUG_CELL_COLOR,
          }}
        >
          {gameData.latestMoveRowColPos &&
          gameData.latestMoveRowColPos[0] === rowPos &&
          gameData.latestMoveRowColPos[1] === columnPos ? (
            <div
              style={{
                width: 8,
                height: 8,
                background: "red",
                borderRadius: "50%",
              }}
            ></div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  // useEffect(() => {
  //   fetchGameList(selectedTournament, currentPage);
  // }, []);
  useEffect(() => {
    if (isMobile) {
      setDynamicCellSize(
        (screenWidth - NECESSARY_COMPLETE_BORDER) /
          (CELL_PER_ROW +
            BOARD_POSITION_LABEL_WIDTH * 2 +
            OFFSET_EMPTY_AREA * 2)
      );
    }
    // fetchGameList(selectedTournament, currentPage);
  }, []);

  useEffect(() => {
    // console.log("useEffectx", currentPage);
    fetchTournamentList();
    fetchGameList(selectedTournament, currentPage);
  }, [currentPage]);

  return (
    <div className="dfc" style={{ width: "100%", background: mainBg }}>
      <div
        className={`sidebar ${isSidebarOpen ? "open" : ""}`}
        id="tournament_part"
        style={{ textAlign: "center" }}
      >
        <div
          style={{
            padding: 10,
            background: "",
            color: "white",
          }}
        >
          Tournament Titles
        </div>
        <div className="dfc dfac" style={{ background: "" }}>
          {tourneyList &&
            tourneyList.map((member, idx) => {
              return (
                <div
                  // className="gold-gradient"
                  className="button-like"
                  key={"tourney_" + idx}
                  style={{
                    // background:
                    //   member.eid === selectedTournament ? "blue" : "white",
                    color:
                      member.eid === selectedTournament ? "white" : "black",
                    background:
                      member.eid === selectedTournament
                        ? tourneyButton.active
                        : tourneyButton.normal,
                    padding: "10px 12px",
                    border: "1px solid black",
                    borderRadius: BORDER_RADIUS_BOARD,
                    width: 200,
                  }}
                  onClick={() => {
                    clickTourney(member);
                  }}
                >
                  {member.event_name}
                </div>
              );
            })}
        </div>

        <div style={{ color: "white", marginTop: 10, fontSize: "0.9em" }}>
          Contact: hellokomol@hotmail.com
        </div>
      </div>

      {/* Overlay for mobile when the sidebar is open */}
      {isSidebarOpen && (
        <div className="overlay show" onClick={toggleSidebar}></div>
      )}

      {/* Button to toggle the sidebar */}
      {isSidebarOpen || isMobile ? (
        // <button className="" onClick={toggleSidebar}>
        //   {isSidebarOpen ? "Close" : "Select Event"}
        // </button>
        <div
          className="button-like"
          // style={{ background: "red", height: 30 }}
          onClick={toggleSidebar}
        >
          {isSidebarOpen ? "Close" : "Select Tournament"}
        </div>
      ) : null}

      <div
        id="main_content"
        className="main-content"
        style={{ padding: "10px 0px" }}
      >
        <div
          id="page_controller"
          className="dfc dfac dfjc"
          style={{ background: "" }}
        >
          <div style={{ color: "white" }}>
            Page: {currentPage} / {totalPage} &nbsp;
            {tourneyList &&
              tourneyList.find((item) => {
                return item.eid == selectedTournament;
              })?.event_name}
          </div>
          <div style={{ margin: "8px 8px" }}>
            {Array.from({ length: totalPage }, (_, index) => {
              const pageNumber = index + 1;
              return (
                <button
                  key={pageNumber}
                  onClick={() => {
                    clickTargetPage(pageNumber);
                  }}
                  style={{
                    padding: "5px 10px",
                    cursor: "pointer",
                    backgroundColor:
                      currentPage === pageNumber ? "#007bff" : "#f0f0f0",
                    color: currentPage === pageNumber ? "white" : "black",
                    border: "1px solid #ddd",
                    borderRadius: "3px",
                  }}
                >
                  {pageNumber}
                </button>
              );
            })}
          </div>
        </div>

        <div
          id="board_group"
          className="dfr"
          style={{
            flexWrap: "wrap",
            color: "white",
            // marginLeft: 20,
            fontSize: "0.9em",
            // background: "pink",
          }}
        >
          {gameDataList &&
            gameDataList.length > 0 &&
            gameDataList.map((gameData, idx) => {
              return (
                <div
                  key={"game_" + gameData.gid}
                  style={{
                    marginLeft: isMobile ? 0 : 8,
                    marginTop: 8,
                    background: "black",
                    borderRadius: BORDER_RADIUS_BOARD,
                    // borderTopLeftRadius: "12px",
                    // borderTopRightRadius: "12px",
                    // border: "1px solid white",
                  }}
                >
                  <div className="dfr dfjc" style={{ margin: "6px 0px" }}>
                    {gameData.black}:{gameData.black_rank} VS {gameData.white}:
                    {gameData.white_rank}
                  </div>
                  <div
                    className="dfr dfjb"
                    style={{ margin: "3px 0px 6px", padding: "0px 6px" }}
                  >
                    <div className="dfr dfjc">
                      Game: {currentPage * BOARDS_PER_PAGE + idx + 1} Move:{" "}
                      {gameData.move}{" "}
                    </div>
                    <div style={{ color: "red" }}>
                      {!gameData.inEditMode &&
                      gameData.move === gameData.gameplayOnly.length
                        ? "Result:" + gameData.result
                        : ""}
                    </div>
                    <div className="dfr dfjc">
                      {gameData.event} {gameData.play_date}
                    </div>
                  </div>

                  <div
                    style={{
                      position: "relative",
                      // background: "silver",
                      width: getWholeBoardWidth(
                        isMobile,
                        screenWidth,
                        dynamicCellSize
                      ),
                      height:
                        getWholeBoardWidth(
                          isMobile,
                          screenWidth,
                          dynamicCellSize
                        ) + GAME_CONTROL_BAR_HEIGHT,
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        background: "blue",
                      }}
                    >
                      <img
                        src="/empty_board.png"
                        alt="My Image"
                        // width={CELL_SIZE * (CELL_PER_ROW + 2)}
                        width={getWholeBoardWidth(
                          isMobile,
                          screenWidth,
                          dynamicCellSize
                        )}
                      />
                    </div>
                    <div
                      style={{
                        // background: "purple",
                        position: "absolute",
                        // border: "1px solid yellow",
                        // marginLeft: CELL_SIZE / 2 - 1,
                        // marginTop: CELL_SIZE / 2 - 4,
                        marginLeft:
                          dynamicCellSize * (1 + OFFSET_EMPTY_AREA) -
                          dynamicCellSize / 2 -
                          0,
                        marginTop:
                          dynamicCellSize * (1 + OFFSET_EMPTY_AREA) -
                          dynamicCellSize / 2 -
                          0,
                        width: dynamicCellSize * INTERSECTION_PER_ROW,
                        height: dynamicCellSize * INTERSECTION_PER_ROW,
                        boxSizing: "border-box",
                      }}
                    >
                      {gameData.liveBoard &&
                        gameData.liveBoard.map((row, rowIndex) => {
                          return (
                            <div key={"row_" + rowIndex} className="dfr">
                              {row.map((item, columnIndex) => {
                                return getStone(
                                  "column_" + columnIndex + "_row_" + rowIndex,
                                  rowIndex + 1,
                                  columnIndex + 1,
                                  item,
                                  gameData
                                );
                              })}
                            </div>
                          );
                        })}
                    </div>

                    <div
                      style={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        // width: CELL_SIZE * (CELL_PER_ROW + 2),
                        width: getWholeBoardWidth(
                          isMobile,
                          screenWidth,
                          dynamicCellSize
                        ),
                        // background: "green",
                        height: GAME_CONTROL_BAR_HEIGHT,
                      }}
                    >
                      <div
                        className="dfr dfac dfjc"
                        style={{
                          background: gameData.inEditMode ? "red" : "white",
                          height: "100%",
                          borderBottomLeftRadius: "12px",
                          borderBottomRightRadius: "12px",
                        }}
                      >
                        <IconButton
                          disabled={gameData.move == 0}
                          onClick={() => {
                            clickJumpNext(gameData, -1, ESTIMATE_END_MOVE);
                          }}
                        >
                          <SkipPrevious
                            sx={{
                              fontSize: PLAY_BUTTON_SIZE,
                              color: gameData.inEditMode
                                ? ICON_BTN_EDIT_MODE_COLOR
                                : ICON_BTN_COLOR,
                            }}
                          />
                        </IconButton>

                        <IconButton
                          disabled={gameData.move == 0}
                          onClick={() => {
                            clickJumpNext(gameData, -1);
                          }}
                        >
                          <FastRewind
                            sx={{
                              fontSize: PLAY_BUTTON_SIZE,
                              color: gameData.inEditMode
                                ? ICON_BTN_EDIT_MODE_COLOR
                                : ICON_BTN_COLOR,
                            }}
                          />
                        </IconButton>

                        <IconButton
                          sx={{ color: ICON_BTN_COLOR }}
                          disabled={gameData.move == 0}
                          onClick={() => {
                            wrapClickNextMove(gameData, -1);
                          }}
                        >
                          <PlayArrow
                            sx={{
                              fontSize: PLAY_BUTTON_SIZE,
                              color: gameData.inEditMode
                                ? ICON_BTN_EDIT_MODE_COLOR
                                : ICON_BTN_COLOR,
                            }}
                            style={{ transform: "scaleX(-1)" }}
                          />
                        </IconButton>

                        <div
                          style={{
                            width: 40,
                            // minWidth: 40,
                            // height: "100%",
                          }}
                        >
                          {gameData.inEditMode ? (
                            <div
                              className="dfr dfac dfjc"
                              style={{ height: "100%", color: "white" }}
                              onClick={() => {
                                exitEditMode(gameData);
                              }}
                            >
                              Exit
                            </div>
                          ) : null}
                        </div>

                        <IconButton
                          sx={{}}
                          disabled={
                            gameData.move == gameData.gameplayOnly.length
                          }
                          onClick={() => {
                            wrapClickNextMove(gameData, 1);
                          }}
                        >
                          <PlayArrow
                            sx={{
                              fontSize: PLAY_BUTTON_SIZE,
                              color: gameData.inEditMode
                                ? ICON_BTN_EDIT_MODE_COLOR
                                : ICON_BTN_COLOR,
                            }}
                          />
                        </IconButton>

                        <IconButton
                          sx={{ color: ICON_BTN_COLOR }}
                          disabled={
                            gameData.move == gameData.gameplayOnly.length
                          }
                          onClick={() => {
                            clickJumpNext(gameData, 1);
                          }}
                        >
                          <FastForward
                            sx={{
                              fontSize: PLAY_BUTTON_SIZE,
                              color: gameData.inEditMode
                                ? ICON_BTN_EDIT_MODE_COLOR
                                : ICON_BTN_COLOR,
                            }}
                          />
                        </IconButton>

                        <IconButton
                          sx={{ color: ICON_BTN_COLOR }}
                          disabled={
                            gameData.move == gameData.gameplayOnly.length
                          }
                          onClick={() => {
                            clickJumpNext(gameData, 1, ESTIMATE_END_MOVE);
                          }}
                        >
                          <SkipNext
                            sx={{
                              fontSize: PLAY_BUTTON_SIZE,
                              color: gameData.inEditMode
                                ? ICON_BTN_EDIT_MODE_COLOR
                                : ICON_BTN_COLOR,
                            }}
                          />
                        </IconButton>
                      </div>

                      {/* <IconButton iconStyle={{ width: 100 }}>
            <LastPage />
          </IconButton> */}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Homepage;
