export const EMPTY_SPOT = "-";
export const BLACK_SPOT = "B";
export const WHITE_SPOT = "W";
export const engWithoutILabels = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "g",
    "h",
    "j",
    "k",
    "l",
    "m",
    "n",
    "o",
    "p",
    "q",
    "r",
    "s",
    "t",
];