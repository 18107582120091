import { INTERSECTION_PER_ROW } from "../const/const_number";
import { EMPTY_SPOT } from "../const/const_string";

export function checkAlive(realBoard: string[][],
    boardWithChunk: boolean[][],
    color: string) {
    for (var i = 1; i <= boardWithChunk.length; i++) {
        for (var j = 1; j <= boardWithChunk[i - 1].length; j++) {
            if (boardWithChunk[i - 1][j - 1]) {
                if (i - 1 >= 1) {
                    if (realBoard[i - 1 - 1][j - 1] === EMPTY_SPOT) {
                        // console.log("alive top");
                        return true;
                    } else {

                    }
                }
                if (i + 1 <= INTERSECTION_PER_ROW) {
                    if (realBoard[i + 1 - 1][j - 1] === EMPTY_SPOT) {
                        // console.log("alive bottom", i + 1 - 1, j - 1, realBoard[i + 1 - 1][j - 1]);
                        return true;
                    } else {

                    }
                }
                if (j - 1 >= 1) {
                    if (realBoard[i - 1][j - 1 - 1] === EMPTY_SPOT) {
                        // console.log("alive left");
                        return true;
                    } else {

                    }
                }
                if (j + 1 <= INTERSECTION_PER_ROW) {
                    if (realBoard[i - 1][j + 1 - 1] === EMPTY_SPOT) {
                        // console.log("alive right", i - 1, j + 1 - 1, realBoard[i - 1][j + 1 - 1]);
                        return true;
                    } else {

                    }
                }
            }
        }
    }
    return false;
} 