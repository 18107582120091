import { BLACK_SPOT, EMPTY_SPOT, WHITE_SPOT } from "../const/const_string";

export function getDoubleDimensionArray(rows: number, columns: number, value: any) {
    // const rows = 5; // Number of rows
    // const columns = 4; // Number of columns

    // Creating a 2D array with specific size (rows x columns)
    const twoDimensionalArray = Array.from({ length: rows }, () => Array(columns).fill(value));
    return twoDimensionalArray;

}

export function getNumberFromChar(char: string): number {
    // Check if the character is a lowercase letter
    if (char >= 'a' && char <= 'z') {
        return char.charCodeAt(0) - 96;
    }
    return -1; // Return -1 if the input is not a lowercase letter
}
export function getCharFromNumber(num: number): string {
    if (num >= 1 && num <= 26) {
        return String.fromCharCode(num + 96);
    }
    return ''; // Return an empty string if the input is out of range
}
export function isOpponent(myStone: string, targetStone: string | undefined): boolean {
    if ((myStone === WHITE_SPOT && targetStone === BLACK_SPOT) ||
        (myStone === BLACK_SPOT && targetStone === WHITE_SPOT)) {
        return true;
    }
    return false;
}

export function getOpponentColor(myStone: string): string {
    if (myStone === WHITE_SPOT) {
        return BLACK_SPOT;
    } else if (myStone === BLACK_SPOT) {
        return WHITE_SPOT;
    }
    return EMPTY_SPOT;
}

export function getStoneColorFromMove(gameplayOnly: string[]
    , moveNumber: number): string {
    // return getOpponentColor(gameplayOnly[moveNumber - 1][0]);
    return gameplayOnly[moveNumber - 1][0];
}

export function isDataFromLeela(gameplayDataString: string): boolean {
    if (gameplayDataString.substring(0, 3) === ";B[") {
        return false;
    }
    return true;
}