// export const PLAY_BUTTON_SIZE = 64;
// export const PLAY_BUTTON_SIZE = 40;
export const PLAY_BUTTON_SIZE = 32;
// export const CELL_SIZE = 24;
export const DEFAULT_CELL_SIZE = 22;
// export const CELL_SIZE = 38;
export const CELL_PER_ROW = 18;
export const INTERSECTION_PER_ROW = CELL_PER_ROW + 1;
export const BOARD_POSITION_LABEL_WIDTH = 1;
export const GAME_CONTROL_BAR_HEIGHT = 40;
export const PAGE_CONTROL_BAR_HEIGHT = 32;
export type DIRECTION_NUMBER = -1 | 1;
export const JUMP_MOVE_AMOUNT = 10;
export const ESTIMATE_END_MOVE = 500;
export const BOARDS_PER_PAGE = 6;
export const STONE_SCALE = 96;
export const BORDER_RADIUS_BOARD = "4%";
// export const JUMP_MOVE_AMOUNT = 5;
export const CELL_SIZE_WITHOUT_BORDER = 30;
export const starPoints = [
    [3, 3],
    [3, 9],
    [3, 15],
    [9, 3],
    [9, 9],
    [9, 15],
    [15, 3],
    [15, 9],
    [15, 15],
];
// export const OFFSET_EMPTY_AREA = 0.8;
export const OFFSET_EMPTY_AREA = 0.8235;
export const NECESSARY_COMPLETE_BORDER = 1;
